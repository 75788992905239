import {
  SlotAvailability,
  LocationType,
} from '@wix/ambassador-availability-calendar/types';
import {
  ReservedLocationIds,
  Service,
  ServiceLocationType,
} from '@wix/bookings-uou-types';

export const areAllLocationsSelected = (selectedLocations: string[]) => {
  return selectedLocations.length === 0;
};

export const isOtherLocationsSelected = (selectedLocations: string[]) => {
  return selectedLocations.includes(ReservedLocationIds.OTHER_LOCATIONS);
};

export const isOnlyBusinessLocationsSelected = (
  selectedLocations: string[],
) => {
  return (
    !areAllLocationsSelected(selectedLocations) &&
    !isOtherLocationsSelected(selectedLocations)
  );
};

export const filterServicesBySelectedLocations = (
  selectedLocations: string[],
  services: Service[],
) => {
  if (!areAllLocationsSelected(selectedLocations)) {
    return services.filter((service) => {
      return service.locations.some((location) => {
        if (location.type === ServiceLocationType.OWNER_BUSINESS) {
          return selectedLocations.includes(location.businessLocation?.id!);
        }
        return selectedLocations.includes(ReservedLocationIds.OTHER_LOCATIONS);
      });
    });
  }
  return services;
};

export const filterSlotsBySelectedLocations = (
  selectedLocations: string[],
  availableSlots?: SlotAvailability[],
) => {
  if (!areAllLocationsSelected(selectedLocations)) {
    return (
      availableSlots?.filter((slotAvailability) => {
        const location = slotAvailability?.slot?.location;
        if (location?.locationType === LocationType.OWNER_BUSINESS) {
          return selectedLocations.includes(location.id!);
        }
        return selectedLocations.includes(ReservedLocationIds.OTHER_LOCATIONS);
      }) || []
    );
  }
  return availableSlots;
};
