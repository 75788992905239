import {
  QueryAvailabilityResponse,
  Slot,
  SlotAvailability,
  SlotResource,
} from '@wix/ambassador-availability-calendar/types';
import { ServiceLocationType } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { LayoutOptions } from '../../types/types';

function toTime(num: number) {
  const s = '00' + num;
  return s.substr(s.length - 2) + ':00';
}

function createSlot({
  id,
  scheduleId,
  serviceId,
  date,
  startTime,
  endTime,
  location,
  resource,
}: {
  id: string;
  scheduleId: string;
  serviceId: string;
  date: string;
  startTime: string;
  endTime: string;
  location: any;
  resource: SlotResource;
}): Slot {
  return {
    sessionId: id,
    scheduleId,
    serviceId,
    startDate: `${date}T${startTime}:00.000Z`,
    endDate: `${date}T${endTime}:00.000Z`,
    location,
    resource,
  };
}

interface SlotOptions {
  start: number;
  duration: number;
  openSpots: number;
  staffMember: string;
  locationName: string;
}

function createSlotAvailability(slotDate: Date, { start, duration, openSpots, staffMember, locationName }: SlotOptions) {
  const [date] = slotDate.toISOString().split('T');
  return {
    bookable: true,
    openSpots,
    totalSpots: 10,
    slot: createSlot({
      id: `${start}`,
      scheduleId: `123`,
      serviceId: 'some-id',
      date,
      startTime: toTime(start),
      endTime: toTime(start + duration),
      location: {
        locationType: ServiceLocationType.OWNER_BUSINESS,
        formattedAddress: 'Shlomo Ibn Gabirol Street 114',
        id: 'location-1-id',
        name: locationName,
      },
      resource: {
        id: 'staff-1-id',
        name: staffMember,
      },
    }),
  };
}

function getWeeklyTimeslotsDailySlotsCount(dayIndex: number) {
  switch (dayIndex) {
    case 2:
    case 3:
      return 6;
    case 4:
      return 0;
    default:
      return 4;
  }
}

function getWeeklyTimetableDailySlotsCount(dayIndex: number) {
  switch (dayIndex) {
    case 0:
      return 2;
    case 4:
      return 1;
    case 5:
      return 0;
    default:
      return 3;
  }
}

const getDailySlots = (date: Date, slotsCount: number, optionsFactory: (index: number) => SlotOptions) => new Array(slotsCount)
  .fill('')
  .map(
    (_, index): SlotAvailability => createSlotAvailability(date, optionsFactory(index)),
  );

const getDefaultSlotOptions = (index: number): SlotOptions => ({
  start: index,
  duration: 1,
  openSpots: 5,
  staffMember: 'Staff 1',
  locationName: 'Tel Aviv',
});

const getWeeklyTimetableSlotOptions = ({ translations: { t } }: ControllerFlowAPI) => (index: number): SlotOptions => ({
  start: 8 + index * 2,
  duration: 2,
  openSpots: 3,
  staffMember: t('dummy-content.service.staff'),
  locationName: t('dummy-content.service.location'),
});

export function createDummySlots(
  flowAPI: ControllerFlowAPI, {
  from,
  calendarLayout = LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER,
}: {
  from?: string;
  calendarLayout?: LayoutOptions;
} = {}): QueryAvailabilityResponse {
  let availabilityEntries: SlotAvailability[] = [];
  const date = from ? new Date(from) : new Date();

  switch (calendarLayout) {
    case LayoutOptions.WEEKLY_TIME_SLOTS:
      for (let dayInTheWeek = 0; dayInTheWeek < 7; dayInTheWeek++) {
        date.setDate(date.getDate() + 1);
        const numberOfSlots = getWeeklyTimeslotsDailySlotsCount(dayInTheWeek);
        const availabilityEntriesForThisDay = getDailySlots(date, numberOfSlots, getDefaultSlotOptions);
        availabilityEntries.push(...availabilityEntriesForThisDay);
      }
      break;

    case LayoutOptions.WEEKLY_TIMETABLE:
      for (let dayInTheWeek = 0; dayInTheWeek < 7; dayInTheWeek++) {
        date.setDate(date.getDate() + 1);
        const numberOfSlots = getWeeklyTimetableDailySlotsCount(dayInTheWeek);
        const availabilityEntriesForThisDay = getDailySlots(date, numberOfSlots, getWeeklyTimetableSlotOptions(flowAPI));
        availabilityEntries.push(...availabilityEntriesForThisDay);
      }
      break;

    case LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER:
      const availabilityEntriesForToday = getDailySlots(new Date(), 3, getWeeklyTimetableSlotOptions(flowAPI))
        availabilityEntries.push(...availabilityEntriesForToday);
      break;

    default:
      const nextYearDate = new Date();
      nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
      availabilityEntries = new Array(18)
        .fill('')
        .map((_, index): SlotAvailability => {
          nextYearDate.setDate(index + 1);
          return createSlotAvailability(nextYearDate, getDefaultSlotOptions(index));
        });
      break;
  }

  return {
    availabilityEntries,
  };
}
